<template>

  <div class="row share-user-row">
    <div class="col-7" v-html="getUserTitle"></div>

    <div class="col-5">
     <select class="form-select" @change="theRoleHasChanged" :disabled="isCurrentUser" v-model="selectedRole" placeholder="Auswählen">
      <option v-for="role in theRoles" :key="role.id" :value="role.id">{{role.name}}</option>
    </select>
  </div>
</div>

</template>

<script>
import { changeShareStatus } from '@/api/collection';

export default {
  name: 'shareDialogUserRow',
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
    roles: {
      type: Array,
      default: null,
      required: true,
    },
  },
  mounted() {
    this.theRoles.push({'name':'Entfernen','id':'delete'});
  },
  data() {
    return {
      loading: false,
      theRoles: Array.from(this.roles),
      selectedRole: this.user.role.id,
    };
  },
  computed: {
    currentUser(){
      return this.$store.state.user;
    },
    getUserTitle(){
      if(Object.prototype.hasOwnProperty.call(this.user,'user') && this.user.user != null){
        return "<span class='name'>"+this.user.user.name+"</span><br><span class='email'>"+this.user.user.email+"</span>";
      }
      else{
        return "<span class='name'>"+this.user.email+"</span>";
      }
    },
    isCurrentUser(){
      if(Object.prototype.hasOwnProperty.call(this.user,'user') && this.user.user!=null && this.user.user.id == this.currentUser.id){
        return true;
      }
      return false;
    },
  },
  methods: {
    theRoleHasChanged(){
      this.loading = true;
      console.log(this.user);
      changeShareStatus(this.user.id, this.selectedRole)
      .then(() => {
        this.$emit('reload');
        if(this.selectedRole == "delete"){

          this.$notify({
            duration: 2500,
            title: 'Berechtigung angepasst',
            text: 'Die Berechtigung für diese Nutzer*in wurde erfolgreich gelöscht.'
          });

        }
        else{

          this.$notify({
            duration: 2500,
            title: 'Berechtigung angepasst',
            text: 'Die Berechtigung für diese Nutzer*in wurde erfolgreich angepasst.'
          });

        }
      })
      .catch(error => {
        console.log(error);

        this.$notify({
          type: 'error',
          duration: 2500,
          title: 'Fehler',
          text: 'Beim Anpassen der Berechtigung für diese Nutzer*in ist ein Fehler aufgetreten.'
        });

      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.share-user-row{
  margin-bottom: 20px;
}

.form-select{
  line-height: 1.2;
}

</style>
