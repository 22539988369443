<template>
    <div class="post merkliste-profil offers btm-menu">
        <Breadcrumb :background="true" :content="breadcrumb"/>

        <div class="profile">
            <div class="container-fluid">
                <div class="profile-main unfixed">
                    <div class="profile-main__info">

                        <h5 v-if="contents!==null">{{contents.length}} Inhalt(e)</h5>
                        <share-button v-if="$isDesktopScreen && collection.collection_type!=='user'"/>
                        <div class="profile-main__info-title">
                            <h3>{{title}}</h3>
                            <share-button v-if="$isMobileScreen && collection.collection_type!=='user'" />
                        </div>
                        <div class="del-edit mt-5 d-flex align-items-center btn-container">
                            <button class="btn btn-profile icon-before mr20 new-btns btn-focus-design" @click="showShareCollectionDialog=true" v-if="canShareCollection">
                                <i class="material-icons new-icons" aria-hidden="true">share</i>
                                <span class="btn-text">Teilen</span>
                            </button>
                            <button class="btn btn-profile icon-before mr20 new-btns btn-focus-design" @click="showEditCollection" v-if="canEditCollection">
                                <i class="material-icons new-icons" aria-hidden="true">edit</i>
                                <span class="btn-text">Bearbeiten</span>
                            </button>
                            <button class="btn btn-profile icon-before new-btns btn-focus-design" @click="showDeleteCollectionModal=true" v-if="canDeleteCollection">
                                <i class="material-icons new-icons" aria-hidden="true">delete</i>
                                <span class="btn-text">Löschen</span>
                            </button>
                        </div>
                        <p class="mt10 do-not-translate" v-if="institution!==null">{{institution}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-lg-7">
                                <div class="about-content">
                                    <h5 class="post-heading">Beschreibung</h5>
                                    <p>{{description}}</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section watchlist" v-if="contents.length>0">
            <div class="container-fluid">
                <div class="content karte-container">

                    <div class="content-scrolling" v-if="$isDesktopScreen">
                        <div class="content-scrolling__cards">
                            <div class="vertical-scroll-box" v-for="(item, index) in contents" :key="index">
                                <angebot-card v-if="item.content_type_name=='angebot'" target="map" :item="item" :autoWidth="true" :id="'vertical-content-'+item.id"  @highlight="highlightThisContent"/>
                                <ort-card v-if="item.content_type_name=='institution'" target="map" :item="item" :autoWidth="true" :id="'vertical-content-'+item.id"  @highlight="highlightThisContent"/>
                                <bildung-card v-if="item.content_type_name=='schule' || item.content_type_name=='kita'" target="map" :item="item" :autoWidth="true" :id="'vertical-content-'+item.id"  @highlight="highlightThisContent"/>
                                <ratgeber-card v-if="item.content_type_name=='ratgeberartikel'" class="ratgeberCard" target="map" :content="item" :autoWidth="true" :id="'vertical-content-'+item.id"  @highlight="highlightThisContent"/>
                            </div>
                        </div>
                    </div>

                    <multiple-map ref="theMap" class="content-map karte" :contents="contents" @highlight="highlightThisContent"/>

                    <div class="karte-cards" v-if="$isMobileScreen">
                        <div class="container-fluid">
                            <div class="row mobile--scrolling horizontal-scroll">
                                <map-card v-for="(item, index) in contents" :key="index" :item="item" @highlight="highlightThisContent" :id="'horizontal-content-'+item.id"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <random-merklisten :exclude="parseInt(id)"></random-merklisten>
        <delete-collection-modal v-if="this.collection!=null && showDeleteCollectionModal" :collection="this.collection" @closeModal="showDeleteCollectionModal=false"></delete-collection-modal>
        <share-dialog :collection="collection" v-if="collection!=null && showShareCollectionDialog" @closeModal="showShareCollectionDialog=false"></share-dialog>

    </div>
</template>

<script>
import Resource from '@/api/resource';
const collectionResource = new Resource('collections');
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { getFieldValues, showLoader, hideLoader } from '@/utils/helpers';
import DeleteCollectionModal from '@/components/modals/deleteCollection.vue';
import ShareDialog from '@/components/modals/shareCollection/shareDialog.vue';
import VueScrollTo from 'vue-scrollto';
import checkPermissions from '@/utils/permission';

export default {
    name: 'Merkliste',
    mixins: [screenSizeMixin],
    components: {
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        OrtCard: () => import('@/components/cards/Ort.vue'),
        BildungCard: () => import('@/components/cards/Bildung.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        MapCard: () => import('@/components/cards/Map.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        MultipleMap: () => import('@/components/map/multiple.vue'),
        RandomMerklisten: () => import('./components/randomMerklisten.vue'),
        DeleteCollectionModal,
        ShareDialog,
    },
    data() {
        return {
            collection: null,
            showDeleteCollectionModal: false,
            showShareCollectionDialog: false,
            scrollVerticalOptions: {
                container: '.content-scrolling',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: false,
                y: true,
            },
            scrollHorizontalOptions: {
                container: '.horizontal-scroll',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: true,
                y: false,
            },
        }
    },
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getCollection();
          this.$scrollTo();
      }
  }
},
created() {
  this.getCollection();
},
computed: {
    canShareCollection(){
        if(this.checkPermissions({elem: this.collection ,permission: 'shareCollections'})){
          return true;
      }
      return false;
  },
  canEditCollection(){
    if(this.checkPermissions({elem: this.collection ,permission: 'editCollections'})){
      return true;
  }
  return false;
},
canDeleteCollection(){
    if(this.checkPermissions({elem: this.collection ,permission: 'deleteCollections'})){
      return true;
  }
  return false;
},
user(){
  return this.$store.state.user;
},
id() {
    return this.$route.params.id;
},
breadcrumb() {
    return "Merklisten | " + this.title; 
},
linkTo(){
    return "/merkliste/"+this.id;
},
title(){
    if(this.collection!==null){
        return this.collection.name;
    }
    return null;
},
contents(){
    if(this.collection!==null){
        return this.collection.contents;
    }
    return null;
},
institution(){
    if(this.collection!==null){
        var owner = this.collection.owner;
        if(owner!=null && owner.institution!==null){
            return owner.institution.title;
        }
    }
    return null;
},
description(){
    if(this.collection!==null){
        return this.collection.description;
    }
    return null;
},
},
methods: {
    checkPermissions,
    showLoader, 
    hideLoader,
    getFieldValues,
    showEditCollection(){
        this.$router.push({ name: 'MerklisteEdit', params: {id: this.$route.params.id } });
    },
    getCollection() {
        this.loader = this.showLoader(this.loader);
        collectionResource.get(this.id)
        .then(response => {
          this.collection = response.data;
      })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
      });
    },
    highlightThisContent(params){
        Object.keys(this.contents).forEach((k) => {
            this.contents[k].highlight = false;
            if(this.contents[k].id == params.content.id){
                this.contents[k].highlight = true;

                if(params.sender != "map"){
                    /*open the popup*/
                    this.$refs.theMap.openPopup(params.content);
                }
                else{
                    if(this.$isMobileScreen){
                        VueScrollTo.scrollTo(('#horizontal-content-'+this.contents[k].id), '2000',this.scrollHorizontalOptions);
                    }
                    else{
                        VueScrollTo.scrollTo(('#vertical-content-'+this.contents[k].id), '2000',this.scrollVerticalOptions);
                    }
                }
            }
        });
    }
}

}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.watchlist {
    display: block!important;
}

.about{
    padding-bottom: 10px;
}

.karte-container{
    @media (max-width: 991px) {
        position: relative;
        background: #d7d0d0;
        height: 100%;
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
    }
}

.post{
    @media (max-width: 991px) {
        margin-bottom: 100px;
    }
}

.karte-cards {
    width:100%;
    bottom: 0;
    z-index: 1001;
    position: absolute; 
}

.ratgeberCard {
    margin-top: 35px !important;
}

.btn-bottom {


    @media (max-width: 786px) {
        margin: 0 auto;
        background: #fff;
        color: $secondary;
    }

}

.profile-main {
    padding-bottom: 40px !important;

    @media (max-width: 991px) {
        padding-bottom: 20px !important;
    }
}

.new-icons {
    vertical-align: middle;
    padding-right: 5px;

    @media (max-width: 786px) {
        padding-right: 5px;
    }
}

.btn-text {
    @media (max-width: 786px) {
       display: none;
   }
}

.btn.btn-profile {
    width: 220px;

    @media (max-width: 985px) {
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 100px;
    }

    @media (max-width: 500px) {
        margin-bottom: 5px;
    }

    i.material-icons {
        left: 20px;
        @media (max-width: 985px) {
            position: relative;
            left: 2px;
        }
    }

    span {
        @media (max-width: 985px) {
            display: none;
        }
    }
}

body[dir="rtl"] {
    .new-icons {
        padding-left: 5px;
        padding-right: 0;

        @media (max-width: 985px) {
            right: 3px;
        }
    }

    .new-btns {
        margin-right: 0px !important;
    }
}
</style>
